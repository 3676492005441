import apiRequest from '../../js/helpers/apiRequest.js';

import {
  SET_DIRECTIONS,
  SET_CULTURES,
  SET_REGIONS,
  SET_DISTRICTS,
  SET_STATUSES,
  SET_FILTER_STATUS,
  SET_FILTERS_LOAD,
} from '../mutations.js';

export default {
  state: {
    active: false,
    filtersLoad: false,
    directions: [],
    cultures: [],
    regions: [],
    districts: [],
    statuses: [],
  },

  mutations: {
    [SET_DIRECTIONS](state, directions) {
      state.directions = directions;
    },

    [SET_CULTURES](state, cultures) {
      state.cultures = cultures;
    },

    [SET_REGIONS](state, regions) {
      state.regions = regions;
    },

    [SET_DISTRICTS](state, districts) {
      state.districts = districts;
    },

    [SET_STATUSES](state, statuses) {
      state.statuses = statuses;
    },

    [SET_FILTER_STATUS](state, payload) {
      state.active = payload;
    },

    [SET_FILTERS_LOAD](state) {
      state.filtersLoad = true;
    },
  },

  actions: {
    async getOptions({ commit }) {
      try {
        const res = await apiRequest('/options', 'GET', null, true);

        if (res.options) {
          const {
            directions,
            cultures,
            regions,
            districts,
            statuses,
          } = res.options;

          commit(
            SET_DIRECTIONS,
            directions.sort((a, b) => a.name.localeCompare(b.name))
          );
          commit(
            SET_CULTURES,
            cultures.sort((a, b) => a.name.localeCompare(b.name))
          );
          commit(SET_REGIONS, regions);
          commit(
            SET_DISTRICTS,
            districts.map(({ id, name, region_id }) => ({
              id,
              name,
              regionId: region_id,
            }))
          );
          commit(SET_STATUSES, statuses);
          commit(SET_FILTERS_LOAD);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
