<template>
  <router-link
    :to="profile ? '/profile' : $route.path"
    :class="['user', { 'user-profile': profile, 'user-light': light }]"
  >
    <div class="user-inner">
      <div class="user-avatar">
        <a-avatar :size="40" :src="avatar" icon="user"></a-avatar>
      </div>

      <div class="user-info">
        <div class="user-title">
          <slot name="title">{{ title }}</slot>
          <span v-if="profile" class="user-profile-icon">
            <a-icon type="more"></a-icon>
          </span>
        </div>
        <div class="user-subtitle">
          <slot name="subtitle">{{ subtitle }}</slot>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'User',

  props: {
    to: {
      type: String,
      default: '/',
    },

    profile: {
      type: Boolean,
      default: false,
    },

    light: {
      type: Boolean,
      default: false,
    },

    avatar: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.user {
  display: block;
  color: $black-200;
  transition: 0.15s;

  &:hover {
    opacity: 0.8;
  }

  &.user-light {
    color: $white;
  }
}

.user-inner {
  display: flex;
  align-items: center;
}

.user-avatar {
  margin-right: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.user-title {
  font-size: 14px;
}

.user-subtitle {
  margin-top: 2px;
  font-size: 12px;
  color: $gray-200;
}

.user-profile-icon {
  @media (max-width: $sm) {
    display: none;
  }
}
</style>
