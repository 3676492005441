import { BASE_PATH } from '../const/index.js';

const parseUsers = (user) => {
  const {
    id,
    status,
    role,
    first_name,
    last_name,
    email,
    phones,
    photo,
    profile_photo_url,
  } = user;

  return {
    id,
    status,
    role,
    full: `${first_name} ${last_name}`,
    name: first_name,
    lastName: last_name,
    email,
    phone: phones,
    avatar: photo ? `${BASE_PATH}/storage/${photo}` : profile_photo_url,
  };
};

export default parseUsers;
