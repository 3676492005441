import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './js/plugins/ant-components.js';
import './js/plugins/scrollto.js';
import './css/index.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
