<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Container',
};
</script>

<style lang="scss">
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;

  @media (max-width: $xl) {
    padding: 0 20px;
  }
}
</style>
