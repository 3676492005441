<template>
  <div class="navbar">
    <container>
      <a-row type="flex" align="middle" :gutter="15">
        <a-col :lg="{ span: 6 }" :sm="{ span: 12 }" :xs="{ span: 8 }">
          <logo></logo>
        </a-col>

        <a-col class="navbar-statistic" :lg="{ span: 12 }" :xs="{ span: 0 }">
          <statistics></statistics>
        </a-col>

        <a-col
          v-if="user.id"
          :lg="{ span: 6 }"
          :sm="{ span: 12 }"
          :xs="{ span: 16 }"
        >
          <div class="navbar-user">
            <a-dropdown placement="bottomRight">
              <user
                class="ant-dropdown-link"
                :avatar="user.avatar"
                :title="`${user.name} ${user.lastName}`"
                :subtitle="
                  user.role === 'manager'
                    ? 'Менеджер'
                    : user.role === 'admin'
                    ? 'Администратор'
                    : ''
                "
              ></user>

              <a-menu slot="overlay">
                <a-menu-item>
                  <router-link to="/profile">Профиль</router-link>
                </a-menu-item>

                <a-menu-item>
                  <router-link to="/report">Отчеты</router-link>
                </a-menu-item>

                <a-sub-menu
                  v-if="user.role === 'admin'"
                  key="test"
                  title="Настройки"
                >
                  <a-menu-item>
                    <router-link to="/create-object">
                      Создать объект
                    </router-link>
                  </a-menu-item>

                  <a-menu-item>
                    <router-link to="/edit-filters">Фильтры</router-link>
                  </a-menu-item>

                  <a-menu-item>
                    <router-link to="/edit-users">Пользователи</router-link>
                  </a-menu-item>
                </a-sub-menu>

                <a-menu-item>
                  <a @click="logout">Выход</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-col>
      </a-row>
    </container>
  </div>
</template>

<script>
import removeTokenFromLocalStorage from '../js/helpers/removeTokenFromLocalStorage.js';

import Container from './Container.vue';
import Logo from './Logo.vue';
import Statistics from './Statistics.vue';
import User from './User.vue';

export default {
  name: 'Navbar',

  components: {
    Container,
    Logo,
    Statistics,
    User,
  },

  computed: {
    user() {
      return this.$store.state.user.info;
    },

    userRole() {
      return this.user.role === 'manager'
        ? 'Менеджер'
        : this.user.role === 'admin'
        ? 'Администратор'
        : '';
    },
  },

  methods: {
    logout() {
      removeTokenFromLocalStorage();
      this.$router.push('/authorization');
    },
  },
};
</script>

<style lang="scss">
.navbar {
  padding: 30px 0;

  @media (max-width: $xl) {
    padding: 15px 0 30px;
  }
}

.navbar-user {
  display: flex;
  justify-content: flex-end;
}

.navbar-statistic {
  @media (max-width: $lg) {
    display: none;
  }
}
</style>
