<template>
  <div class="page-authorization">
    <div class="page-authorization-inner">
      <div class="page-authorization-logo">
        <logo></logo>
      </div>

      <a-form-model
        ref="authorizationForm"
        class="page-authorization-form"
        :model="form"
        :rules="rules"
        @submit.prevent="onSubmit"
      >
        <a-form-model-item ref="email" label="Ваш email" prop="email">
          <a-input
            size="large"
            placeholder="email@smartseeds.by"
            v-model="form.email"
            @blur="
              () => {
                $refs.email.onFieldBlur();
              }
            "
          ></a-input>
        </a-form-model-item>

        <a-form-model-item ref="password" label="Ваш пароль" prop="password">
          <a-input-password
            size="large"
            placeholder="Пароль"
            v-model="form.password"
            @blur="
              () => {
                $refs.password.onFieldBlur();
              }
            "
          ></a-input-password>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" htmlType="submit" :loading="loading">
            Войти
          </a-button>

          <!-- <a-button class="ml-10">
            Забыл пароль
          </a-button> -->
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import apiRequest from '../js/helpers/apiRequest.js';
import setTokenToLocalStorage from '../js/helpers/setTokenToLocalStorage.js';

import Logo from '../components/Logo';

export default {
  name: 'Authorization',

  components: {
    Logo,
  },

  data() {
    return {
      loading: false,

      form: {
        email: '',
        password: '',
      },

      rules: {
        email: [
          {
            type: 'email',
            required: true,
            message: 'Пожалуйста, введите ваш адрес электронной почты',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Пожалуйста, введите ваш пароль',
            trigger: 'blur',
          },
        ],
      },
    };
  },

  methods: {
    async onSubmit() {
      try {
        const { email, password } = this.form;
        const body = new FormData();

        body.append('username', email);
        body.append('password', password);

        this.loading = true;
        const res = await apiRequest('/login', 'POST', body);
        this.loading = false;

        if (res.success && res.success.token.plainTextToken) {
          setTokenToLocalStorage(res.success.token.plainTextToken);

          Promise.all([
            this.getUserInfo(),
            this.getOptions(),
            this.getManagers(),
          ]).then(() => {
            this.$router.push('/');
            this.$store.commit('app/SET_APP_READY');
          });
        } else {
          this.$notification.warning({
            message: 'МинералАгроСоюз',
            description: 'Не правильно введенные данные',
          });
        }
      } catch (error) {
        console.log('onSubmit - ', error);
      }
    },

    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      getOptions: 'filters/getOptions',
      getManagers: 'managers/getManagers',
    }),
  },
};
</script>

<style lang="scss">
.page-authorization {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/image/authorization-bg.jpg');
}

.page-authorization-inner {
  width: 600px;
  padding: 50px 80px;
  margin: 15px 50px;
  border-radius: 4px;
  background-color: $white;

  @media (max-width: $sm) {
    padding: 15px 15px;
    margin: 15px 15px;
  }
}

.page-authorization-logo {
  text-align: center;
}
</style>
