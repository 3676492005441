import { SET_APP_READY } from '../mutations.js';

export default {
  state: {
    appReady: false,
  },

  mutations: {
    [SET_APP_READY](state) {
      state.appReady = true;
    },
  },
};
