<template>
  <div class="object-layout">
    <container>
      <a-row :gutter="20">
        <a-col :span="24" class="mb-50">
          <router-link to="/" class="back-to-home">
            <a-icon type="home" class="mr-5"></a-icon>

            <span>Вернуться к списку объектов</span>
          </router-link>
        </a-col>

        <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
          <div class="object-layout-sidebar">
            <a-avatar :size="170" class="object-id object-id-big">
              {{ objectId }}
            </a-avatar>

            <div class="object-layout-nav">
              <ul class="object-layout-nav-list">
                <li class="object-layout-nav-list-item">
                  <router-link
                    :to="`/object/${objectId}`"
                    class="object-layout-nav-list-link"
                  >
                    Общая информация
                  </router-link>
                </li>

                <li class="object-layout-nav-list-item">
                  <router-link
                    :to="`/object/history/${objectId}`"
                    class="object-layout-nav-list-link"
                  >
                    История статусов
                  </router-link>
                </li>

                <li class="object-layout-nav-list-item">
                  <router-link
                    :to="`/object/sown-area/${objectId}`"
                    class="object-layout-nav-list-link"
                  >
                    Посевные площади
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </a-col>

        <a-col :md="{ span: 18 }" :xs="{ span: 24 }">
          <a-spin :spinning="objectLoading">
            <router-view></router-view>
          </a-spin>
        </a-col>
      </a-row>
    </container>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import Container from '../components/Container.vue';

export default {
  name: 'ObjectLayout',

  components: {
    Container,
  },

  data() {
    return {
      pageLoading: false,
    };
  },

  watch: {
    async appReady() {
      this.SET_OBJECT_LOADING(true);
      await this.$store.dispatch('objects/getObjectInfo', this.objectId);
      this.SET_OBJECT_LOADING(false);
    },
  },

  computed: {
    objectId() {
      return this.$route.params.id;
    },

    appReady() {
      return this.$store.state.app.appReady;
    },

    objectLoading() {
      return this.$store.state.objects.objectLoading;
    },
  },

  beforeDestroy() {
    this.SET_OBJECT_INFO({});
  },

  async created() {
    if (this.appReady) {
      this.SET_OBJECT_LOADING(true);
      await this.$store.dispatch('objects/getObjectInfo', this.objectId);
      this.SET_OBJECT_LOADING(false);
    }
  },

  methods: {
    ...mapMutations({
      SET_OBJECT_INFO: 'objects/SET_OBJECT_INFO',
      SET_OBJECT_LOADING: 'objects/SET_OBJECT_LOADING',
    }),
  },
};
</script>

<style lang="scss">
.object-layout {
}

.ant-table {
  overflow: hidden;
  width: 100%;
}

.ant-table-content {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

.object-layout-sidebar {
  @media (max-width: $md) {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
}

.object-layout-nav {
  margin-top: 50px;

  @media (max-width: $lg) {
    margin-top: 30px;
  }

  @media (max-width: $md) {
    margin: 0 0 0 20px;
  }
}

.object-layout-nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.object-layout-nav-list-item {
  &:not(:last-of-type) {
    margin-bottom: 30px;

    @media (max-width: $lg) {
      margin-bottom: 20px;
    }

    @media (max-width: $md) {
      margin-bottom: 15px;
    }

    @media (max-width: $sm) {
      margin-bottom: 10px;
    }
  }
}

.object-layout-nav-list-link {
  padding: 3px 0;
  font-weight: 700;
  border-bottom: 3px solid transparent;
  transition: 0.1s;

  &:hover {
    border-bottom-color: $green-dark;
  }

  &.router-link-exact-active {
    border-bottom-color: $green-dark;
  }
}

.object-layout-info-title {
  margin-top: 30px;
  margin-bottom: 5px;
  color: #979797;
}

.object-layout-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: $lg) {
    align-items: flex-start;
    margin-top: 30px;
  }

  .ant-btn {
    &:not(:last-of-type) {
      margin-bottom: 10px;

      @media (max-width: $lg) {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }
}
</style>
