<template>
  <div class="statistics">
    <template v-if="load && regions.length">
      <a-select
        class="statistics-select"
        default-value="null"
        :loading="loading"
        @change="onChangeRegion"
      >
        <a-select-option value="null"> Беларусь </a-select-option>
        <a-select-option
          v-for="region in regions"
          :key="region.id"
          :value="region.id"
        >
          {{ region.name }}
        </a-select-option>
      </a-select>

      <div class="statistics-content">
        <div class="statistics-item green">
          <b>{{ statistics.activeA.value }}</b>
          <span>Активных А</span>
        </div>
        <div class="statistics-item light-blue">
          <b>{{ statistics.activeB.value }}</b>
          <span>Активных Б</span>
        </div>
        <div class="statistics-item orange">
          <b>{{ statistics.new.value }}</b>
          <span>Новых</span>
        </div>
        <!-- <div class="statistics-item cyan">
          <b>{{ statistics.operating.value || 0 }}</b>
          <span>Операционка</span>
        </div> -->
        <div class="statistics-item red">
          <b>{{ statistics.passive.value }}</b>
          <span>Пассивных</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import apiRequest from '../js/helpers/apiRequest.js';

export default {
  name: 'Statistics',

  data() {
    return {
      load: false,
      loading: false,
      statistics: {},
      region: 'null',
    };
  },

  computed: {
    regions() {
      return this.$store.state.filters.regions;
    },
  },

  async created() {
    await this.getStatistics();
    this.load = true;
  },

  methods: {
    async getStatistics() {
      try {
        const path =
          this.region !== 'null' ? `/statistic/${this.region}` : '/statistic';
        const res = await apiRequest(path, 'GET', null, true);

        if (res.statuses) {
          const statuses = res.statuses;
          const activeStatuses = statuses.filter((status) => status.id !== 0);

          this.statistics = {
            activeA: activeStatuses.find((status) => status.id === 1),
            activeB: activeStatuses.find((status) => status.id === 2),
            new: activeStatuses.find((status) => status.id === 4),
            operating: activeStatuses.find((status) => status.id === 5),
            passive: activeStatuses.find((status) => status.id === 3),
          };
        }
      } catch (error) {
        console.log('getStatistics:', error);
      }
    },

    onChangeRegion(val) {
      this.region = val;

      this.$nextTick(async () => {
        this.loading = true;
        await this.getStatistics();
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.statistics-select {
  width: 100%;
  max-width: 180px;
}

.statistics-content {
  display: flex;
  margin-top: 10px;
}

.statistics-item {
  display: flex;
  flex-direction: column;
  line-height: 1;

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  &.green {
    b {
      color: $green;
    }
  }

  &.light-blue {
    b {
      color: $blue-light;
    }
  }

  &.orange {
    b {
      color: $orange;
    }
  }

  &.red {
    b {
      color: $red;
    }
  }

  &.cyan {
    b {
      color: #5bdebb;
    }
  }

  b {
    font-size: 32px;
  }

  span {
    font-size: 10px;
  }
}
</style>
