import parseUsers from './parseUsers.js';

const parseObject = (object) => {
  const {
    id,
    text_status,
    region,
    unp,
    swonarea_text,
    name,
    comment,
    latitude,
    longitude,
    address,
    district,
    district_id,
    users,
    directions,
    no_data,
    updated_at,
  } = object;

  return {
    id,
    status: Number(text_status),
    region,
    unp: unp === 'null' ? null : unp,
    swonareaText:
      swonarea_text === 'null' || swonarea_text === null ? null : swonarea_text,
    name,
    comment: comment === 'null' || comment === null ? '' : comment,
    address,
    district,
    districtId: Number(district_id),
    updatedAt: updated_at,
    coordinates: [String(longitude), String(latitude)],
    managers: users ? users.map(parseUsers) : [],
    directions: directions
      ? directions.map(({ id, name }) => ({ id, name }))
      : [],
    noData: no_data,
  };
};

export default parseObject;
