import apiRequest from '../../js/helpers/apiRequest.js';
import parseUsers from '../../js/helpers/parseUsers.js';

import { SET_USER_INFO } from '../mutations.js';

export default {
  state: {
    info: {},
  },

  mutations: {
    [SET_USER_INFO](state, info) {
      state.info = info;
    },
  },

  actions: {
    async getUserInfo({ commit }) {
      try {
        const res = await apiRequest('/profile', 'GET', null, true);

        if (res.user) {
          commit(SET_USER_INFO, parseUsers(res.user));
        }
      } catch (error) {
        console.log();
      }
    },
  },
};
