import Vue from 'vue';
import {
  ConfigProvider,
  Row,
  Col,
  Avatar,
  FormModel,
  Input,
  Select,
  Radio,
  Switch,
  Upload,
  Button,
  Collapse,
  Table,
  List,
  Icon,
  Spin,
  Tag,
  Modal,
  Tooltip,
  Divider,
  Pagination,
  Dropdown,
  Menu,
  Empty,
  DatePicker,
  Popconfirm,
  notification,
} from 'ant-design-vue';

Vue.use(ConfigProvider);
Vue.use(Row);
Vue.use(Col);
Vue.use(Avatar);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(Upload);
Vue.use(Button);
Vue.use(Collapse);
Vue.use(Table);
Vue.use(List);
Vue.use(Icon);
Vue.use(Spin);
Vue.use(Tag);
Vue.use(Modal);
Vue.use(Tooltip);
Vue.use(Divider);
Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Empty);
Vue.use(DatePicker);
Vue.use(Popconfirm);

Vue.use({
  install(Vue) {
    Vue.prototype.$notification = notification;
  },
});
