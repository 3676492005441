<template>
  <router-link to="/" class="logo">
    <img src="../assets/image/logo.png" class="logo-image" alt="logo" />
  </router-link>
</template>

<script>
export default {
  name: 'Logo',
};
</script>

<style lang="scss">
.logo {
  display: inline-block;
  max-width: 260px;
}

.logo-image {
  display: block;
  width: 100%;
}
</style>
