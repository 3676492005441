import { format } from 'date-fns';
import apiRequest from '../../js/helpers/apiRequest.js';
import parseObject from '../../js/helpers/parseObject.js';
import parseUsers from '../../js/helpers/parseUsers.js';

import {
  SET_OBJECTS,
  SET_OBJECT_INFO,
  SET_POINTS,
  SET_PAGINATION_INFO,
  SET_LAST_OBJECT_STATUS,
  SET_OBJECT_HISTORY_STATUS,
  SET_OBJECT_SWON_AREAS,
  SET_OBJECTS_LOADING,
  SET_OBJECT_LOADING,
} from '../mutations.js';

export default {
  state: {
    objects: [],
    points: {},
    object: {},
    page: 1,
    currentPage: 1,
    totalPages: 1,
    totalObjects: 1,
    perPage: 1,
    loading: false,
    objectLoading: false,
  },

  mutations: {
    [SET_OBJECTS](state, objects) {
      state.objects = objects;
    },

    [SET_OBJECT_INFO](state, object) {
      state.object = object;
    },

    [SET_LAST_OBJECT_STATUS](state, payload) {
      state.object.lastStatus = payload;
    },

    [SET_OBJECT_HISTORY_STATUS](state, payload) {
      state.object.historyStatus = payload;
    },

    [SET_OBJECT_SWON_AREAS](state, payload) {
      state.object.swonAreas = payload;
    },

    [SET_POINTS](state, points) {
      state.points = points;
    },

    [SET_PAGINATION_INFO](state, payload) {
      state.currentPage = payload.currentPage;
      state.totalPages = payload.totalPages;
      state.totalObjects = payload.totalObjects;
      state.perPage = payload.perPage;
    },

    [SET_OBJECTS_LOADING](state, payload) {
      state.loading = payload;
    },

    [SET_OBJECT_LOADING](state, payload) {
      state.objectLoading = payload;
    },
  },

  actions: {
    async getObjects({ commit }, page) {
      try {
        const res = await apiRequest(
          `/objects?page=${page}`,
          'GET',
          null,
          true
        );

        if (res.objects) {
          const {
            data,
            current_page,
            last_page,
            total,
            per_page,
          } = res.objects;

          commit(SET_PAGINATION_INFO, {
            currentPage: current_page,
            totalPages: last_page,
            totalObjects: total,
            perPage: per_page,
          });

          commit(SET_OBJECTS, data.map(parseObject));
        }
      } catch (error) {
        console.log('getObjects:', error);
      }
    },

    async getObjectInfo({ commit, rootState }, id) {
      try {
        const res = await apiRequest(`/object/${id}`, 'GET', null, true);

        if (res) {
          const {
            object,
            managers,
            directions,
            cultures,
            latest_history,
            histories,
            squares,
          } = res;

          const findDirection = (id) => {
            return rootState.filters.directions.find(
              (direction) => direction.id == id
            ).name;
          };

          commit(SET_OBJECT_INFO, {
            info: parseObject(object),
            contacts: object.contacts,
            managers: managers.map(parseUsers),
            directions: directions
              ? directions.map(({ id, name }) => ({ id, name }))
              : [],
            cultures: cultures
              ? cultures.map(({ id, name }) => ({ id, name }))
              : [],
          });

          commit(
            SET_LAST_OBJECT_STATUS,
            latest_history
              ? [
                  {
                    key: latest_history.id,
                    status: [latest_history.status],
                    statusId: Number(latest_history.status),
                    manager: latest_history.users[0]
                      ? `${latest_history.users[0].first_name} ${latest_history.users[0].last_name}`
                      : 'Система',
                    managerId: latest_history.users[0]
                      ? Number(latest_history.users[0].id)
                      : null,
                    date: format(
                      new Date(latest_history.date_at),
                      'dd.MM.yyyy'
                    ),
                    dateAt: latest_history.date_at,
                    action: latest_history.activity || '-',
                    direction: latest_history.direction
                      ? findDirection(latest_history.direction)
                      : '-',
                    directionId: latest_history.direction
                      ? Number(latest_history.direction)
                      : null,
                    comment: latest_history.comment || '-',
                  },
                ]
              : []
          );

          commit(
            SET_OBJECT_HISTORY_STATUS,
            histories.map(
              ({
                id,
                status,
                date_at,
                created_at,
                activity,
                direction,
                users,
                comment,
              }) => ({
                key: id,
                status: [Number(status)],
                manager: users[0]
                  ? `${users[0].first_name} ${users[0].last_name}`
                  : 'Система',
                managerId: users[0] ? users[0].id : null,
                date: format(new Date(date_at || created_at), 'dd.MM.yyy'),
                action: activity || '-',
                direction: direction ? findDirection(direction) : '-',
                comment: comment || '-',
              })
            )
          );

          commit(
            SET_OBJECT_SWON_AREAS,
            squares
              .map(
                ({
                  id,
                  culture,
                  square,
                  manufacturer,
                  provider,
                  comment,
                  updated_at,
                }) => ({
                  key: id,
                  updatedAt: updated_at,
                  culture,
                  square: square || '-',
                  manufacturer: manufacturer || '-',
                  provider: provider || '-',
                  comment: comment || '-',
                })
              )
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          );
        }
      } catch (error) {
        console.log('getObjectInfo:', error);
      }
    },

    async getPoints({ commit }) {
      try {
        const res = await apiRequest('/points', 'GET', null, true);

        if (res) {
          commit(SET_POINTS, res);
        }
      } catch (error) {
        console.log('getPoints:', error);
      }
    },
  },
};
