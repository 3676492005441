import Vue from 'vue';
import VueRouter from 'vue-router';
import Authorization from '../views/Authorization.vue';
import MainLayout from '../layout/MainLayout.vue';
import ObjectLayout from '../layout/ObjectLayout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main-view',
    component: MainLayout,
    auth: true,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => {
          return import(
            /* webpackChunkName: "main" */ '../views/Dashboard.vue'
          );
        },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => {
          return import(/* webpackChunkName: "main" */ '../views/Profile.vue');
        },
      },
      {
        path: '/report',
        name: 'report',
        component: () => {
          return import(/* webpackChunkName: "main" */ '../views/Report.vue');
        },
      },
      {
        path: '/create-object',
        name: 'create-object',
        component: () => {
          return import(
            /* webpackChunkName: "main" */ '../views/CreateObject.vue'
          );
        },
      },
      {
        path: '/edit-filters',
        name: 'edit-filters',
        component: () => {
          return import(
            /* webpackChunkName: "main" */ '../views/EditFilters.vue'
          );
        },
      },
      {
        path: '/edit-users',
        name: 'edit-users',
        component: () => {
          return import(
            /* webpackChunkName: "main" */ '../views/EditUsers.vue'
          );
        },
      },
      {
        path: '/object/:id',
        name: 'object-id',
        auth: true,
        component: ObjectLayout,
        children: [
          {
            path: '/object/:id',
            name: 'object-id',
            component: () => {
              return import(
                /* webpackChunkName: "object" */ '../views/Object.vue'
              );
            },
          },
          {
            path: '/object/history/:id',
            name: 'object-history-id',
            component: () => {
              return import(
                /* webpackChunkName: "object" */ '../views/ObjectHistory.vue'
              );
            },
          },
          {
            path: '/object/sown-area/:id',
            name: 'object-sown-area-id',
            component: () => {
              return import(
                /* webpackChunkName: "object" */ '../views/ObjectSownArea.vue'
              );
            },
          },
        ],
      },
    ],
  },
  {
    path: '/authorization',
    name: 'Authorization',
    component: Authorization,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const userAuthenticated = localStorage.getItem('access_token');

  if (
    to.path !== '/authorization' &&
    to.path !== 'authorization' &&
    !userAuthenticated
  ) {
    next({ path: '/authorization' });
  } else if (
    (to.path === '/authorization' || to.path === 'authorization') &&
    userAuthenticated
  ) {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;
